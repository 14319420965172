import { render, staticRenderFns } from "./answer.vue?vue&type=template&id=e54795fc&scoped=true&"
import script from "./answer.vue?vue&type=script&lang=js&"
export * from "./answer.vue?vue&type=script&lang=js&"
import style0 from "./answer.vue?vue&type=style&index=0&id=e54795fc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e54795fc",
  null
  
)

export default component.exports